import { themeColors, UITheme } from '@wearemojo/ui-constants';
import { LinearGradient } from 'expo-linear-gradient';
import {
	createShimmerPlaceholder,
	ShimmerPlaceholderProps,
} from 'react-native-shimmer-placeholder';

const ShimmerPlaceholder = createShimmerPlaceholder(LinearGradient);

const ShimmerLoader = ({ children, ...props }: ShimmerPlaceholderProps) => {
	return (
		<ShimmerPlaceholder
			shimmerColors={[
				themeColors[UITheme.dark].fill_neutral,
				'#edece8',
				themeColors[UITheme.dark].fill_neutral,
			]}
			{...props}
		>
			{children}
		</ShimmerPlaceholder>
	);
};

export default ShimmerLoader;
