import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

type Props = {
	children: React.ReactNode;
};
export const TextAlignWrapper = ({ children }: Props) => {
	const { styles } = useStyles(stylesheet);
	return <View style={styles.container}>{children}</View>;
};

const stylesheet = createStyleSheet(() => ({
	container: {
		flex: 1,
		minWidth: '100%',
	},
}));
