import { UIThemeColors } from '@wearemojo/ui-constants';
import { ComponentType } from 'react';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import BackgroundView from '../BackgroundView';
import Button from '../Button';
import CMSContent from '../CMSContent';
import Stack from '../Stack';
import Text from '../Text';
import TextAction from '../TextAction';
import { BlockContent, MojoIcon } from '../utils/types';

type Props = {
	icon?: ComponentType<MojoIcon>;
	iconColor?: string;
	iconContainerSize?: 'lg' | 'xl';
	iconBackgroundColor?: 'background_level_2' | 'background_level_1';
	ctaIcon?: ComponentType<MojoIcon>;
	secondaryCtaIcon?: ComponentType<MojoIcon>;
	contentColor?: keyof UIThemeColors;
	title: string;
	content: BlockContent;
	ctaText: string;
	onCtaPress: () => void;
	secondaryCtaText?: string;
	onSecondaryCtaPress?: () => void;
	textActionCta?: string;
	onTextActionCtaPress?: () => void;
};

const AbstractModalView = ({
	icon: Icon,
	iconColor,
	iconContainerSize = 'lg',
	iconBackgroundColor = 'background_level_2',
	ctaIcon,
	secondaryCtaIcon,
	contentColor = 'content_neutral100',
	title,
	content,
	ctaText,
	secondaryCtaText,
	textActionCta,
	onCtaPress,
	onSecondaryCtaPress,
	onTextActionCtaPress,
}: Props) => {
	const {
		styles,
		theme: { spacing, colors, iconSize },
	} = useStyles(stylesheet, { iconContainerSize });
	return (
		<Stack spacing={spacing.xl}>
			<Stack spacing={spacing.md}>
				{Icon && (
					<BackgroundView
						background={iconBackgroundColor}
						style={styles.iconContainer}
					>
						<Icon
							color={iconColor ? iconColor : colors.fill_highlight}
							size={iconSize.lg}
						/>
					</BackgroundView>
				)}
				<Text.CMSContent
					value={title}
					variant="heading_sm"
					style={styles.textCenter}
				/>
			</Stack>
			<Stack spacing={spacing.sm}>
				<Text.TextContextProvider
					style={styles.textCenter}
					themeColor={contentColor}
				>
					<CMSContent value={content} />
				</Text.TextContextProvider>
			</Stack>
			<Stack spacing={spacing.xs}>
				<Button
					onPress={onCtaPress}
					title={ctaText}
					iconPosition="right"
					icon={ctaIcon}
				/>
				{secondaryCtaText != null && onSecondaryCtaPress != null && (
					<Button
						onPress={onSecondaryCtaPress}
						type="secondary"
						iconPosition="right"
						icon={secondaryCtaIcon}
						title={secondaryCtaText}
					/>
				)}
				{textActionCta != null && onTextActionCtaPress != null && (
					<TextAction onPress={onTextActionCtaPress} title={textActionCta} />
				)}
			</Stack>
		</Stack>
	);
};

const stylesheet = createStyleSheet(({ radius }) => ({
	iconContainer: {
		variants: {
			iconContainerSize: {
				lg: {
					width: 64,
					height: 64,
				},
				xl: {
					width: 90,
					height: 90,
				},
			},
		},
		borderRadius: radius.full,
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'center',
	},
	textCenter: {
		textAlign: 'center',
	},
}));

export default AbstractModalView;
