import ArrowRightIcon from '@wearemojo/icons/Outline/Arrows/ArrowRightIcon';
import { BrandColor } from '@wearemojo/ui-constants';
import { ComponentType } from 'react';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import PressableOpacity from './PressableOpacity';
import Text from './Text';
import { MojoIcon } from './utils/types';

// TODO: Change BrandColor to tokens

export type Props = {
	title: string;
	icon?: ComponentType<MojoIcon>;
	disabled?: boolean;
	onPress?: () => void;
	position?: 'start' | 'center' | 'end';
	variant?: 'primary' | 'secondary' | 'secondaryDark' | 'secondaryLight';
	noPadding?: boolean;
};

const TextAction = ({
	title,
	icon: Icon,
	disabled = false,
	onPress,
	position,
	variant = 'primary',
	noPadding = false,
}: Props) => {
	const { styles, theme } = useStyles(stylesheet, {
		variant,
		position,
		noPadding,
	});
	const iconColor = styles.text.color;
	const ActionIcon = Icon ?? ArrowRightIcon;

	return (
		<PressableOpacity
			onPress={!disabled ? onPress : undefined}
			disabled={disabled}
			style={styles.root}
		>
			<Text
				variant="action"
				style={[styles.text, disabled && styles.textDisabled]}
			>
				{title}
			</Text>

			<ActionIcon
				size={theme.iconSize.xs}
				color={iconColor}
				stroke={iconColor}
			/>
		</PressableOpacity>
	);
};

const stylesheet = createStyleSheet(({ spacing }) => ({
	root: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		gap: spacing.xs,
		paddingHorizontal: spacing.xl,
		paddingVertical: spacing.md,

		variants: {
			position: {
				start: {
					alignSelf: 'flex-start',
				},
				center: {
					alignSelf: 'center',
				},
				end: {
					alignSelf: 'flex-end',
				},
			},
			noPadding: {
				true: {
					paddingHorizontal: 0,
					paddingVertical: 0,
				},
			},
		},
	},
	text: {
		textTransform: 'uppercase',

		variants: {
			variant: {
				primary: {
					color: BrandColor.primary_yellow,
				},
				secondary: {
					color: BrandColor.neutral_200,
				},
				secondaryDark: {
					color: BrandColor.black,
				},
				secondaryLight: {
					color: BrandColor.white,
				},
			},
		},
	},
	textDisabled: {
		variants: {
			variant: {
				primary: {
					color: BrandColor.neutral_400,
				},
				secondary: {
					color: BrandColor.neutral_400,
				},
				secondaryDark: {
					color: BrandColor.neutral_200, // @TODO: not yet represented in DS, update to match when present
				},
				secondaryLight: {
					color: BrandColor.neutral_200,
				},
			},
		},
	},
}));

export default TextAction;
