import { PortableTextTypeComponentProps } from '@portabletext/react';
import { ImageWithAlignmentType } from '@wearemojo/sanity-schema/schema/block/ImageWithAlignment';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import Image from '../Image';
import { useCMSGlobalContext } from '../utils/cms';
import CMSBlock from './CMSBlock';

const CMSImageWithAlignmentBlock = ({
	value: { image, alignment },
}: PortableTextTypeComponentProps<ImageWithAlignmentType>) => {
	const { imgAttr } = useCMSGlobalContext();
	const { styles } = useStyles(stylesheet, { alignment });

	const {
		uri,
		format,
		altText,
		dimensions: { aspectRatio, width: maxWidth } = {},
	} = imgAttr(image);
	return (
		<CMSBlock>
			<View style={styles.container}>
				<Image
					source={{ uri }}
					aspectRatio={aspectRatio}
					style={{ maxWidth }}
					format={format}
					alt={!altText ? '' : altText}
				/>
			</View>
		</CMSBlock>
	);
};

const stylesheet = createStyleSheet(() => ({
	container: {
		variants: {
			alignment: {
				center: {
					alignItems: 'center',
				},
			},
		},
	},
}));

export default CMSImageWithAlignmentBlock;
