import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import BackgroundView from './BackgroundView';
import ContainedAction from './ContainedAction';

type Props = {
	onPress: () => void;
	hasActivity?: boolean;
};

const HelpButton = ({ onPress, hasActivity }: Props) => {
	const { styles } = useStyles(stylesheet);

	return (
		<View style={styles.container}>
			<ContainedAction onPress={onPress} type="help" />
			{hasActivity && (
				<BackgroundView background="fill_warning" style={styles.activityDot} />
			)}
		</View>
	);
};

const ACTIVITY_DOT_SIZE = 8;

const stylesheet = createStyleSheet({
	container: {
		position: 'relative',
	},
	activityDot: {
		position: 'absolute',
		right: -1,
		top: 0,
		width: ACTIVITY_DOT_SIZE,
		height: ACTIVITY_DOT_SIZE,
		borderRadius: ACTIVITY_DOT_SIZE,
	},
});

export default HelpButton;
