import { InlineContentWrapperType } from '@wearemojo/sanity-schema';
import { ImageProps } from 'expo-image';
import { View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import AccountAvatar from '../AccountAvatar';
import Text from '../Text';

type ItoAIMessageProps = {
	messages: string[] | InlineContentWrapperType[];
	avatarUrl: ImageProps['source'];
};

const ItoAIMessage = ({ messages, avatarUrl }: ItoAIMessageProps) => {
	const { styles } = useStyles(stylesheet);

	return (
		<View style={styles.messageContainer}>
			{messages.map((message, index) => {
				const isFirst = index === 0;
				return (
					<View
						key={index}
						style={[styles.message, !isFirst && styles.textMargin]}
					>
						{isFirst && (
							<AccountAvatar size="small" avatarUrl={avatarUrl} noShimmer />
						)}
						<View style={styles.textContainer}>
							<Text.CMSContent
								value={message}
								variant="body_md"
								themeColor="content_neutral100"
							/>
						</View>
					</View>
				);
			})}
		</View>
	);
};

const stylesheet = createStyleSheet(({ spacing }) => ({
	messageContainer: {
		gap: spacing.xs,
	},
	message: {
		maxWidth: '80%',
		flexDirection: 'row',
		gap: spacing.md,
	},
	textContainer: {
		width: '100%',
	},
	textMargin: {
		marginLeft: spacing.xl4,
	},
}));

export default ItoAIMessage;
