import { PortableTextComponentProps } from '@portabletext/react';
import { AIMessageBlockType } from '@wearemojo/sanity-schema';

import ItoAIMessage from '../ito/ItoAIMessage';
import { useCMSGlobalContext } from '../utils/cms';
import CMSBlock from './CMSBlock';

type CMSAIMessageProps = PortableTextComponentProps<AIMessageBlockType>;

const CMSAIMessageBlock = ({ value }: CMSAIMessageProps) => {
	const { therapistImage } = useCMSGlobalContext();

	return (
		<CMSBlock>
			<ItoAIMessage messages={[value.content]} avatarUrl={therapistImage} />
		</CMSBlock>
	);
};

export default CMSAIMessageBlock;
