const itoAvatar = require('./ito-avatar.png');
const cancelation50Off = require('./cancellation-50-off-discount.png');
const coinImage = require('./coin.png');
const fill1 = require('./patterns/fill_1.png');
const fill2 = require('./patterns/fill_2.png');
const fill3 = require('./patterns/fill_3.png');
const pattern1 = require('./patterns/pattern_1.png');
const pattern2 = require('./patterns/pattern_2.png');
const pattern3 = require('./patterns/pattern_3.png');
const pattern4 = require('./patterns/pattern_4.png');
const pattern5 = require('./patterns/pattern_5.png');
const pattern6 = require('./patterns/pattern_6.png');
const pattern7 = require('./patterns/pattern_7.png');
const therapists = {
	mateo: require('./therapists/mateo.png'),
	cassandra: require('./therapists/cassandra.png'),
	jordan: require('./therapists/jordan.png'),
	rachel: require('./therapists/rachel.png'),
};
const onboardingGlowing = require('./glowing-backgrounds/onboarding_glowing.png');
const itoOnboardingGradient = require('./glowing-backgrounds/ito_onboarding_gradient.png');

export {
	cancelation50Off,
	coinImage,
	fill1,
	fill2,
	fill3,
	itoAvatar,
	itoOnboardingGradient,
	onboardingGlowing,
	pattern1,
	pattern2,
	pattern3,
	pattern4,
	pattern5,
	pattern6,
	pattern7,
	therapists,
};
